import {useRoute, useCookie, useRequestHeaders} from '#imports';

export function useIOSWebViewConsent() {
    const route = useRoute();
    const headers = useRequestHeaders();
    const optOutFromIOSAppCookie = useCookie<boolean>('optOutFromIOSApp');

    const userAgent = import.meta.server ? headers['user-agent'] : navigator.userAgent;

    function isUserAgentFromIOS() {
        return /ipad|iphone/i.test(userAgent);
    }

    function hasOptOut() {
        const optOutQueryParam = route.query.opt_out_from_ios_app;
        if (typeof optOutQueryParam === 'string') {
            optOutFromIOSAppCookie.value = optOutQueryParam === 'true';
        }
        return optOutFromIOSAppCookie.value ?? false;
    }

    function hasIOSUserOptOut() {
        return isUserAgentFromIOS() && hasOptOut();
    }

    return {
        hasIOSUserOptOut,
    };
}
