import type {Script} from '@unhead/schema';
import type {HostTrackingSettings} from '../types';
import {useDataLayer} from '../composables/useDataLayer';
import {useIOSWebViewConsent} from './useIOSWebViewConsent';
import {useRequestHeaders, useRoute} from '#imports';

export function isRequestFromBot(headers: Readonly<Record<string, string | undefined>>) {
    const maltBotScore = Number(headers['x-malt-bot-score']);
    return maltBotScore <= 40;
}

async function gtmFbqScriptAsString(): Promise<string | null> {
    try {
        const {gtmFbqScript} = await import('../scripts/gtm');
        return `${gtmFbqScript}
        gtmFbqScript();`;
    } catch (e) {
        return null;
    }
}

async function gtmDataLayerScriptAsString(googleTagManagerId: string, dataLayer: string): Promise<string | null> {
    try {
        const {gtmDataLayerScript} = await import('../scripts/gtm');
        return `${gtmDataLayerScript}
        gtmDataLayerScript('${googleTagManagerId}', ${dataLayer})`;
    } catch (e) {
        return null;
    }
}

export function getGoogleTagManagerScripts(googleTagManagerId: string | undefined, dataLayer: string): Script[] {
    return !googleTagManagerId
        ? []
        : [
              {
                  children: gtmFbqScriptAsString(),
                  type: 'text/javascript',
              },
              {
                  children: gtmDataLayerScriptAsString(googleTagManagerId, dataLayer),
                  type: 'text/javascript',
              },
          ];
}

async function oneTrustScriptAsString(): Promise<string | null> {
    try {
        const {oneTrustScript} = await import('../scripts/ot');
        return `${oneTrustScript}
        oneTrustScript();`;
    } catch (e) {
        return null;
    }
}

export function getOneTrustScripts(oneTrustId?: string): Script<{
    'data-domain-script'?: string;
    'data-document-language'?: string;
}>[] {
    const {hasIOSUserOptOut} = useIOSWebViewConsent();

    const isMicroFrontEnd = useRoute().query?.['app-type'] === 'microfrontend';

    return !oneTrustId || hasIOSUserOptOut() || isMicroFrontEnd
        ? []
        : [
              {
                  src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
                  type: 'text/javascript',
                  'data-domain-script': oneTrustId,
                  'data-document-language': 'true',
                  async: true,
              },
              {
                  // callback called by the One Trust script after the user makes their choice
                  children: oneTrustScriptAsString(),
                  async: true,
                  type: 'text/javascript',
              },
          ];
}

export function useScripts(hostSettings?: HostTrackingSettings | null): Script[] {
    if (!hostSettings) {
        return [];
    }

    const headers = useRequestHeaders();
    if (isRequestFromBot(headers)) {
        return [];
    }

    const dataLayer = useDataLayer();
    const dataLayerAsString = JSON.stringify(dataLayer.value);

    return [
        ...getGoogleTagManagerScripts(hostSettings.googleTagManagerId, dataLayerAsString),
        ...getOneTrustScripts(hostSettings.oneTrustCookieConsentURL),
    ];
}
